<template>
        <main-template>
            <template v-slot:headerLottieDark>
                <dotlottie-player :src="getDarkLogo()"
                                  background="transparent" speed="1"  style="width: 450px; height: 80px" direction="1" playMode="normal"  no-autoplay></dotlottie-player>
            </template>
            <template v-slot:headerLottieLight>

                <dotlottie-player :src="getLightLogo()"
                                  background="transparent" speed="1"  style="width: 450px; height: 80px" direction="1" playMode="normal"  no-autoplay></dotlottie-player>
            </template>
            <template v-slot:header>Procure</template>
            <template v-slot="slotProps">
            <fetch-siren-root ref="SirenRoot" :linkName='linkName' :link="link">

                <div slot-scope="{ response }" class="flex flex-grow overflow-hidden">

                        <div class="flex w-full overflow-hidden">
                            <div class="flex w-full h-full p-2 overflow-hidden">
                                <div class="rounded-xl bg-v3-base-500 w-full h-full margin-10 dark:bg-v3-gray-900 overflow-hidden">
                                    <loading v-if="!response"></loading>
                                    <template v-else>
                                        <SingleRentalItemDetails ref="orderDetails" :details="response.body" :loadingDetails="false" :hideTable="hideTable" @setLoading="setLoading" @getDetails="getDetails" :hasTable="false"/>
                                    </template>
                                </div>
                            </div>
                        </div>


                </div>
            </fetch-siren-root>
            </template>
        </main-template>
</template>

<script>
import FetchSirenRoot from '@/components/FetchSirenRoot';
import fileDownload from "js-file-download";
import Loading from '@/components/Loading';
import MainTemplate from '@/v3/templates/MainTemplate';
import {mapGetters} from "vuex";
import SingleRentalItemDetails from "@/v3/pages/SingleRentalItemDetails.vue";
import Siren from "super-siren";

export default {
    inject: ["$validator"],
    components: {
        SingleRentalItemDetails,
        FetchSirenRoot,
        Loading,
        MainTemplate,

    },
    data() {
        return {
            slotProps: null,
            users: null,
            details: null,
            detailsOpen: false,
            loadingDetails: false,
            orders: null,
            tabOpen: 'basics',
            editing: false,
            submittingModal: false,
            hideTable: false,
            editingOrderItems: false
        }
    },
    computed: {
        ...mapGetters({
            accessToken: "getAccessToken",
            user: "getUser"
        }),
        linkName() {
            return null;
        },
        link(){
            // if(Object.keys(this.$route.query).length === 0){
            //     return '/orders';
            // }
            return this.$route.fullPath.replace('/v3','')
        }

    },
    mounted() {
        let follow = Siren.Helper.follow;
    },
    methods: {
        getOrders(response) {
            if (!response) {
                return null;
            }
            return response.body.entities.filter(entity => entity.rels.contains('order'));
        },
        showDetails(details){
            this.$setLaravelValidationErrorsFromResponse('');
            this.details = details;
            this.loadingDetails = false;
        },
        setLoading(){
            this.$refs.orderDetails.editing = false;
            this.$refs.orderDetails.tabOpen = 'basics';
            this.details = null;
            this.loadingDetails = true;
        },
        getDetails(entity){
            this.$router.go(0);
        },
        getDarkLogo()
        {
            return process.env.VUE_APP_API_URL + "/api/json/lottie?type=procure_dark"
        },
        getLightLogo()
        {
            return process.env.VUE_APP_API_URL + "/api/json/lottie?type=procure_light"
        },
        formSuccess(){
            this.$refs.SirenRoot.load();
        },
        saveClicked() {
            if(!this.submitting) {
                this.$setLaravelValidationErrorsFromResponse('');
                this.submitting = true;
                this.$refs.supplierDetails.updateAction.perform(this.$refs.supplierDetails.updateActionData).then(res => {
                    if (res.type === 'text/csv') {
                        fileDownload(res.text, 'export.csv');
                        this.submitting = false;
                        return;
                    }
                    this.$refs.supplierDetails.editing = false;
                    this.submitting = false;
                    this.$store.dispatch(
                        "setMessage",
                        this.messages(res).entity.properties.get("success")
                    );
                    this.details = res.body;
                    this.formSuccess();
                    this.$emit('success', res);
                }).catch(error => {
                    this.submitting = false;
                    if (error.status === 422) {
                        this.$setLaravelValidationErrorsFromResponse(
                            JSON.parse(error.response.text)
                        );
                    } else {
                        alert('There was an error processing your request.' + error);
                    }
                    this.$emit('failure');
                });
            }
        },
        messages(response) {
            return response.body.entities
                .filter(function (entity) {
                    return entity.rels.contains("status-messages");
                })
                .first();
        },

    }
}
</script>

